import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Layout from "../components/Layout"
import { Link } from "gatsby"

const heroText = false

export default function Projects() {
  return (
    <Layout
      bodyClass="projects single"
      heroTitle="This is the title of the project"
      heroText={heroText}
      bcText="PROJECTS"
    >
      <section className="project-section">
        <Container>
          <Row>
            <Col sm={12}>
              <span className="red-subtitle">PROJECT DESCRIPTION</span>
              <div className="project-content">
                <p>
                  Lorem ipsum dolor sit amet. Ut voluptate sunt sed dolorem
                  ipsum eos vero quod ut optio consequatur. Eum dolorum corporis
                  ut saepe nihil aut exercitationem rerum ut animi modi sit
                  assumenda aperiam. Qui ipsa rerum est cupiditate quod qui
                  consequuntur architecto sed amet molestias aut omnis porro id
                  soluta quae ad rerum obcaecati.
                </p>
                <p>
                  Et quaerat atque est officia laborum vel dolore sapiente hic
                  rerum quasi! Et galisum magnam At maiores dolorum ea odit
                  suscipit vel molestias odio et quia voluptatum et sunt tenetur
                  sed unde galisum. Aut nostrum voluptas eos consequatur unde
                  vel dolores quia aut minus quia vel consequatur fugit sit
                  impedit animi eos consequatur galisum. Et aperiam rerum ex
                  dicta fugiat et magni autem eos architecto voluptatem aut
                  commodi eius? Sit consequatur corrupti ea consequatur omnis ut
                  nemo quia non voluptatum accusamus. Eos laborum corporis hic
                  rerum expedita sed doloribus dolor rem dolorem consequatur aut
                  nemo quae et officiis totam! Aut veniam itaque ut vitae
                  assumenda sed quia iste sit omnis sunt.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="project-section">
        <Container>
          <Row>
            <Col sm={12} lg={6}>
              <span className="red-subtitle">PROJECT GALLERY</span>
              <figure className="project-gallery-image">
                <img src="images/consultancies.jpg" alt="" />
              </figure>
              <figure className="project-gallery-image">
                <img src="images/consultancies.jpg" alt="" />
              </figure>
            </Col>
            <Col sm={12} lg={{ span: 4, offset: 1 }}>
              <span className="red-subtitle">PROJECT INFORMATION</span>
              <div className="project-info-item">
                <h3>Completion date</h3>
                <p>2017-2020</p>
              </div>
              <div className="project-info-item">
                <h3>Client</h3>
                <p>Client name</p>
              </div>
              <div className="project-info-item">
                <h3>Location</h3>
                <p>Thessaloniki</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="project-separator">
        <Container>
          <Row>
            <Col sm={12}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </Col>
          </Row>
        </Container>
      </div>
      <section className="project-navigation">
        <Container>
          <Row>
            <Col sm={12}>
              <div className="project-nav-wrap">
                <Link
                  to="/project-single"
                  className="arrow-button project-prev"
                >
                  Previous project
                </Link>
                <Link
                  to="/project-single"
                  className="arrow-button project-next"
                >
                  Next project
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}
